import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { sendGetPassengersTypes } from "../../core/passengersTypes/asyncPassengersTypes";
import { sendGetBalanceAndQR, sendGetPassenger, sendGetQR } from "../../core/passenger/asyncPassenger";
import sweetAlert from "sweetalert";
import Loading from "../../components/loading";
import Page from "../../components/page";


import SaldoPassenger from "./SaldoPassenger";
import CardPassenger from "./cardPassenger";
import CardPassengerType from "./cardPassengerType";
import { Container, Grid, Button, Card } from "@mui/material";


export default function PassengerById(props) {

  const dispatch = useDispatch();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);


  const { qr, filteredPassenger, filteredBalanceAndQr ,qrInfo} = useSelector((state) => state.passenger);

 
  const { filteredTypePassenger, passengersTypes, updatingPassengersTypes, passengersTypesUpdateFailed } = useSelector((state) => state.passengersTypes);

  const days = {
    Monday: "Lunes",
    Tuesday: "Martes",
    Wednesday: "Miércoles",
    Thursday: "Jueves",
    Friday: "Viernes",
    Saturday: "Sábado",
    Sunday: "Domingo"
  };

  const location = useLocation();
  const { dataRow } = location.state;

  const [passengerIdTypes, setPassengerIdTypes] = useState();

  useEffect(() => { dispatch(sendGetPassengersTypes()) }, []);
  useEffect(() => {
    if (passengersTypesUpdateFailed) {
      sweetAlert(
        "Error",
        "No se pudo realizar la acción, intente nuevamente",
        "error")
    }
  }, [passengersTypesUpdateFailed]);


  useEffect(() => {
    setIsLoading(updatingPassengersTypes);
    setPassengerIdTypes(passengersTypes.find((ptype) => ptype._id === dataRow.idType));
  }, [updatingPassengersTypes]);



  return (
    <Page title="Administrar pasajero">

      <Grid item m={5}>
        <div>
        </div>
        <CardPassenger
          qr={qr?.qr}
          id={id} 
          desde={qr?.desde}
          hasta={qr?.hasta}/>
      </Grid>

      <div className="containerFlex">
        {!isLoading && (
          <Grid item m={5} >
            <CardPassengerType
              typePassengerId={passengerIdTypes}
              filteredTypePassenger={filteredTypePassenger}
              types={passengersTypes}
              days={days}
              id={id} />
          </Grid>
        )}
        <Grid item m={5}>
          <SaldoPassenger
          />
        </Grid>
      </div>

    </Page>
  );
}