import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Header from "../components/header/header.jsx";
import Sidebar from "../components/sidebar/sidebar.jsx";

import {
  ThemeRoutesSuperAdmin,
  ThemeRoutesBusinessAdmin,
  ThemeRoutesATCAdmin,
} from "../routes/routing.jsx";
import { useSelector } from "react-redux";
import { typeRole } from "../core/types";

export default function Fulllayout(props) {
  const { role } = useSelector((state) => state.auth);
  return (
    <div
      id="main-wrapper"
      data-theme="light"
      data-layout="vertical"
      data-sidebartype="mini-sidebar"
      data-sidebar-position="fixed"
      data-header-position="fixed"
      data-boxed-layout="full">

      <Header />

      <Sidebar
        {...props}
        routes={
          role === typeRole.admin
            ? ThemeRoutesSuperAdmin
            : role === typeRole.atc
            ? ThemeRoutesATCAdmin
            : ThemeRoutesBusinessAdmin  }/>
            

      <div className="page-wrapper d-block">
        <div className="page-content container-fluid">
          <Switch>
            {ThemeRoutesSuperAdmin.map((prop, key) => {
              if (prop.redirect === true) {
                return (
                  <Redirect
                    from={prop.path}
                    to={prop.pathTo}
                    key={key + "routes"}
                  />
                );
              } else {
                return (
                  <Route
                    path={prop.path}
                    component={prop.component}
                    key={key + "routesOther"} /> );}
            })}
          </Switch>
        </div>
       
      </div>
    </div>
  );
}
