import {
  getPassenger,
  createPassenger,
  updatePassenger,
  deletePassenger,
  updatePassengerType,
  getQR,
  getNFC,
  getPassengerFiltered,
  getQRInfo,
  getCheckIn,
  reprocesarCheckIn,
  getBalanceAndQrFiltered,
  getReactivateQrStatic
} from ".";
import axios from "axios";
import { httpAction } from "../helpers/httpActionHelpers";


const baseUrl = process.env.REACT_APP_DEV + "/paseengers";
const baseUrl2 = process.env.REACT_APP_DEV

export function sendGetPassenger() {
  return httpAction(getPassenger().type, async ({ getState }) => {
    const token = getState().auth.token;
    const response = await axios({
      method: "GET",
      url: baseUrl,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
    return response.data.data;
  });
}

export function sendPostPassenger(data) {
  return httpAction(createPassenger().type, async ({ getState }) => {
    const token = getState().auth.token;
    await axios({
      method: "POST",
      url: baseUrl,
      data: data,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
  });
}

export function sendPutPassenger(data) {
  return httpAction(updatePassenger().type, async ({ getState }) => {
    const token = getState().auth.token;
    await axios({
      method: "PUT",
      url: baseUrl,
      data: data,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
  });
}

export function sendDeletePassenger({ id }) {
  return httpAction(deletePassenger().type, async ({ getState }) => {
    const token = getState().auth.token;
    await axios({
      method: "DELETE",
      url: baseUrl,
      data: { id },
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
  });
}

export function sendPutPassengerType(data) {
  return httpAction(updatePassengerType().type, async ({ getState }) => {
    const token = getState().auth.token;
    await axios({
      method: "PUT",
      url: `${baseUrl}/modifyType`,
      data: data,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
  });
}

export function sendGetQR(id) {
  return httpAction(getQR().type, async ({ getState }) => {
    const token = getState().auth.token;
    const response = await axios({
      method: "GET",
      url: baseUrl + "/qrStatic?idPerson=" + id,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
    return response.data.data;
  });
}


export function sendGetPassengerFiltered(dni) {
  return httpAction(getPassengerFiltered().type, async ({ getState }) => {
    const token = getState().auth.token;
    return axios({
      method: "GET",
      url: baseUrl + "/byDni?dni=" + dni,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    }).then(
      function (response) {
        return response.data.data
      }
    ).catch(
      function (error) {
        return console.log(error);
      })
  });
}



export function sendGetQRInfo(dni) {
  return httpAction(getQRInfo().type, async ({ getState }) => {
    const token = getState().auth.token;
    return axios({
      method: "GET",
      url:  baseUrl2 + "/reload/getPassengersByDni?dni=" + dni,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    }).then(
      function (response) {
        return response.data.data
      }
    ).catch(
      function (error) {
        return console.log(error);
      })
  });
}

export function sendGetCheckIn(id) {
  return httpAction(getCheckIn().type, async ({ getState }) => {
    const token = getState().auth.token;
    return axios({
      method: "POST",
    //  url: "http://10.64.8.136:3000/api/v1/reload/searchCheckIn",
      url:  baseUrl2 + "/reload/searchCheckIn",
      data: { "idPassenger": `${id}` },
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    }).then(
      function (response) {
        return response.data.data
      }
    ).catch(
      function (error) {
        return console.log(error);
      })
  });
}


export function sendGetReprocesarCheckIn(_id) {
  return httpAction(reprocesarCheckIn().type, async ({ getState }) => {
    const token = getState().auth.token;
    return axios({
      method: "POST",
      url:  baseUrl2 + "/reload/reprocesarCheckIn",
      data: { "data": [{ "_id": `${_id}` }]},
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    }).then(
      function (response) {
        return response.data.data
      }
    ).catch(
      function (error) {
        return console.log(error);
      })
  });
} 

 export function sendGetBalanceAndQRFiltered(id) {
  return httpAction(getBalanceAndQrFiltered().type, async ({ getState }) => {
    const token = getState().auth.token;
    return axios({
      method: "POST",
      url:  baseUrl2 + "/paseengers/getBalanceAndQR",
      data: { "idPassenger": `${id}` },
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    }).then(
      function (response) {
        return response.data.data
      }
    ).catch(
      function (error) {
        return console.log(error);
      })
  });
} 

//////////////ACTIVAR QR/////////////
 export function sendGetreactivateQrStatic(id) {
  return httpAction(getReactivateQrStatic().type, async ({ getState }) => {
    const token = getState().auth.token;
    return axios({
      method: "POST",
      url: baseUrl + "/reactivateQrStatic",
      data: { "_id": `${id}` },
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    }).then(
      function (response) {
        return response.data.data
      }
    ).catch(
      function (error) {
        return console.log(error);
      })
  });
}  
  