import React, { Ref, forwardRef } from "react";
import MaterialTable from "material-table";
import structuredClone from "@ungap/structured-clone";
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@mui/icons-material";

const color = { color: "#ff9002" };

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} ref={ref} style={color} />
  )),
  Check: forwardRef((props, ref) => (
    <Check {...props} ref={ref} style={color} />
  )),
  Clear: forwardRef((props, ref) => (
    <Clear {...props} ref={ref} style={color} />
  )),
  Delete: forwardRef((props, ref) => (
    <DeleteOutline {...props} ref={ref} style={color} />
  )),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} style={color} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} style={color} />),
  Export: forwardRef((props, ref) => (
    <SaveAlt {...props} ref={ref} style={color} />
  )),
  Filter: forwardRef((props, ref) => (
    <FilterList {...props} ref={ref} style={color} />
  )),
  FirstPage: forwardRef((props, ref) => (
    <FirstPage {...props} ref={ref} style={color} />
  )),
  LastPage: forwardRef((props, ref) => (
    <LastPage {...props} ref={ref} style={color} />
  )),
  NextPage: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} style={color} />
  )),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} style={color} />
  )),
  ResetSearch: forwardRef((props, ref) => (
    <Clear {...props} ref={ref} style={color} />
  )),
  Search: forwardRef((props, ref) => (
    <Search {...props} ref={ref} style={color} />
  )),
  SortArrow: forwardRef((props, ref) => (
    <ArrowDownward {...props} ref={ref} style={color} />
  )),
  ThirdStateCheck: forwardRef((props, ref) => (
    <Remove {...props} ref={ref} style={color} />
  )),
  ViewColumn: forwardRef((props, ref) => (
    <ViewColumn {...props} ref={ref} style={color} />
  )),
};

function EditableTable(props) {
  const safeData = Object.isExtensible(props.data)
    ? props.data
    : structuredClone(props.data);

  return (
    <>
      <MaterialTable
        title={props.title}
        columns={props.columns}
        data={safeData}
        icons={tableIcons}
        editable={{
          onRowAdd: props.onRowAdd,
          onRowUpdate: props.onRowUpdate,
          onRowDelete: props.onRowDelete,
        }}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50],


    //buscador del Mateial Table
          search: false 



        }}

        localization={{
          header: {
            actions: "Acciones",
          },
          body: {
            emptyDataSourceMessage: "No hay datos registrados",
            addTooltip: "Agregar",
            deleteTooltip: "Eliminar",
            editTooltip: "Editar",
            editRow: {
              deleteText: "¿Está seguro que desea eliminar esta fila?",
              cancelTooltip: "Cancelar",
              saveTooltip: "Guardar",
            },
          },
          toolbar: {
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar",
          },
          pagination: {
            labelRowsSelect: "filas",
            labelDisplayedRows: "{from}-{to} de {count}",
            firstTooltip: "Primera página",
            previousTooltip: "Página anterior",
            nextTooltip: "Página siguiente",
            lastTooltip: "Última página",
            labelRowsPerPage: "Filas por página:",
          },
        }} onRowClick={props.onRowClick} />

    </>
  );
}

export default EditableTable;
