import { useEffect } from "react";
import PSADULTOS from "../../assets/images/PSADULTOS.png"
import QRcode from "react-qr-code";

export default function DownloadDocumentHTML({ id, setDownloadPSADULTOS, qr, name, lastName, dni,desde, hasta }) {
  const styles = {
    card: { left: 0, top: 0, position: "relative" },
    target: {
      width: "390px",
      height: "240px",
      position: "relative",
      zIndex: "-1",
    },
    target1: {
      width: "380px",
      height: "240px",
      position: "relative",
      zIndex: "0"
    },
    generatedQr: {
      position: "absolute",
      top: "10px",
      left: "30px",
      zIndex: 1,
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      fontFamily: "sans-serif",
    },

    subtitle: {
      color: "#000000",
      fontSize: "12px",
      top: "95px",
      left: "268px",
      position: "absolute",
      fontFamily: "sans-serif",
      zIndex: 5,
    },
    subtitle1: {
      color: "#000000",
      fontSize: "12px",
      top: "60px",
      left: "238px",
      position: "absolute",
      fontFamily: "sans-serif",
      zIndex: 5,
      textAlign: "center", /* o "left" */
      whiteSpace: "nowrap"
    },
    subtitle2: {
      color: "#000000",
      fontSize: "12px",
      top: "75px",
      left: "268px",
      position: "absolute",
      fontFamily: "sans-serif",
      zIndex: 5,
      textAlign: "center", /* o "left" */
      whiteSpace: "nowrap"
    },

    FechaInicio: {
      top: "119px",
      left: "253px",
      position: "absolute",
      fontSize: "13px",
      color: "#000000",
      fontFamily: "sans-serif"
    },

  };

  useEffect(() => {
    if (qr) {
      const renderDiv = document.getElementById("render");
      const html = renderDiv?.innerHTML;
      var ventana = window.open("", "PRINT"); // "height=400,width=600" 
      ventana.document.write("<html><head><title>" + document.title + "</title>");
      ventana.document.write("</head><body >");
      ventana.document.write(html);
      ventana.document.write("</body></html>");
      ventana.document.close();
      ventana.print();
      ventana.close();
      setDownloadPSADULTOS();
    }
  }, []);

  const fecha = new Date(desde);
  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1;
  const anio = fecha.getFullYear();
  const desdeFecha = `${dia}/${mes}/${anio}`;
  const fecha2 = new Date(hasta);
  const dia2 = fecha2.getDate();
  const mes2 = fecha2.getMonth() + 1;
  const anio2 = fecha2.getFullYear();
  const hastaFecha = `${dia2}/${mes2}/${anio2}`;

  return (

    <div style={{ display: "none" }}>
      <div id="render">
        <div>
          <h3 style={styles.FechaInicio}> {desdeFecha} Valido: {hastaFecha} </h3>
        </div>
        <div style={styles.card}>
          <QRcode value={qr}
            id="QRCodeScaled"
            size={210}
            style={{
              height: "auto", position: "absolute",
              top: "10px",
              left: "10px",
              zIndex: 3
            }} />
          <img src={PSADULTOS} style={styles.target} />
          <h3 style={styles.subtitle}>DNI: {dni}</h3>
          <h3 style={styles.subtitle1}>{name}</h3>
          <h3 style={styles.subtitle2}>{lastName}</h3>
        </div>
      </div>
    </div>


  );
}
