import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import sessionStorage from "redux-persist/lib/storage/session";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import commonReducer from "./common";
import authReducer from "./auth";

import passengerReducer from "./passenger";
import passengersTypesReducer from "./passengersTypes";

import branchsReducer from "./branchs";
import modalitiesReducer from "./modalities";
import servicesReducer from "./services";
import schedulesReducer from "./schedules";
import linesReducer from "./lines";
import businessReducer from "./business";

const rootPersistConfig = {
  key: "root",
  storage: storage,
  keyPrefix: "redux-",
};

export const persistorConf = {
  key: "client",
  storage,
  stateReconciler: hardSet,
};

const sessionPersistConfig = {
  key: "kywaldsodf",
  storage: sessionStorage,
  stateReconciler: hardSet,
};

const rootReducer = combineReducers({
  common: persistReducer(persistorConf, commonReducer),
  auth: persistReducer(sessionPersistConfig, authReducer),


  passenger: passengerReducer,
  passengersTypes: passengersTypesReducer,


  modalities: modalitiesReducer,
  branchs: branchsReducer,
  services: servicesReducer,
  lines: linesReducer,
  schedules: schedulesReducer,
  business: businessReducer,
});

export { rootPersistConfig, rootReducer };
