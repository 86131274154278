import { createSlice } from "@reduxjs/toolkit";
import { SnackbarType } from "../../components/snackbar";

const initialState = {
  isHttpRequestOngoing: false,
  redirectTo: "",
  isLoading: false,
  isSnackbarEnabled: false,
  snackbarMessage: "",
  snackbarType: SnackbarType.ERROR,
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    httpRequestStarted: (state) => {
      state.isHttpRequestOngoing = true;
      state.isLoading = true;
    },
    httpRequestFinished: (state) => {
      state.isHttpRequestOngoing = false;
      state.isLoading = false;
    },

    forceRedirect: (state, action) => {
      state.redirectTo = action.payload.uri;
    },
    stopForceRedirect: (state) => {
      state.redirectTo = "";
    },

    showSnackbar: (state, action) => {
      state.isSnackbarEnabled = true;
      state.snackbarMessage = action.payload.message;
      state.snackbarType = action.payload.type;
    },
    hideSnackbar: (state) => {
      state.isSnackbarEnabled = false;
      state.snackbarMessage = initialState.snackbarMessage;
      state.snackbarType = initialState.snackbarType;
    },
  },
});

const { actions, reducer } = commonSlice;

export const {
  httpRequestStarted,
  httpRequestFinished,
  forceRedirect,
  stopForceRedirect,
  showSnackbar,
  hideSnackbar,
} = actions;

export default reducer;

