import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendGetCheckIn, sendGetPassengerFiltered, sendGetQRInfo } from "../../core/passenger/asyncPassenger";
import Loading from "../../components/loading";
import EditableTable from "../../components/editableTable";
import Page from "../../components/page";
import { Box, Container, Grid } from "@mui/material";
import Images from "../../assets/images/imagen.png";
import sweetAlert from "sweetalert";
import { sendGetTypePassengerFiltered } from "../../core/passengersTypes/asyncPassengersTypes";

export default function QRInformacion() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [dataTable, setDataTable] = useState([]);
    const [BusquedaQR, setBusquedaQR] = useState("");
    const { qrInfo, updatingQrInfo } = useSelector((state) => state.passenger);

    const columns = [{
        title: "Nombre",
        field: "name"
    },
    {
        title: "Apellido",
        field: "lastName"
    },
    {
        title: "DNI",
        field: "dni"
    }];


    useEffect(() => {
        const prxsData = [];
        if (!updatingQrInfo) {
            if (qrInfo) {
                qrInfo.forEach((p) => {
                    prxsData.push({
                        idPassenger: p.idPassenger || "-",
                        id: p._id || "-",
                        name: p.name || "-",
                        lastName: p.lastName || "-",
                        dni: p.dni || "-",
                    });
                })
            }
        } setDataTable(prxsData)
    }, [qrInfo]);



useEffect(() => {
        if (BusquedaQR.length >= 4)
            return dispatch(sendGetQRInfo(BusquedaQR))
    }, [BusquedaQR]);


    function cambiar(e) { setBusquedaQR(e.target.value) }

    function QrInformationDetailed(_event, dataRow) {
        dispatch(sendGetPassengerFiltered(dataRow.dni))
        dispatch(sendGetCheckIn(dataRow.idPassenger))
        return history.push({ pathname: `/QR/${dataRow.id}` })
    }

    return (
        <Page title="QR" >
            <Container >
                <Grid container spacing={6} wrap="nowrap">
                    <Grid item xs={12} sm={"auto"}>
                        <Box>
                            <h3 className="text-title">Buscar : </h3>
                            <div className="input-group">
                                <div className="md-form active-cyan-2 mb-3">
                                    <input className="form-control"
                                        type="text" placeholder="Buscar QR de Pasajero"
                                        aria-label="Search" onChange={cambiar} />
                                </div>
                            </div>
                            <EditableTable title="Qr"
                                columns={columns}
                                data={dataTable}
                                onRowClick={QrInformationDetailed} />
                        </Box>
                    </Grid>
                    <Grid item xl={12} sm={8}>
                        <Box>
                            <h1 className="text-title">Administrar QR</h1>
                            <h4 className="text-subtitle">
                                Página destinada a la administración de Check In de QR
                            </h4>
                            <img src={Images} className="image" alt="image" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
