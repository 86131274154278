import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  business: [],
  updatingBusiness: true,
  businessUpdateFailed: false,
};

const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    getBusiness: (state) => state,
    getBusinessStarted: (state) => initialState,
    getBusinessSuccess: (state, action) => {
      state.business = action.payload;
      state.updatingBusiness = false;
    },
    getBusinessFailed: (state) => {
      state.updatingBusiness = false;
      state.businessUpdateFailed = true;
    },
    cleanGetBusiness: (state) => {
      state.updatingBusiness = true;
      state.businessUpdateFailed = false;
    },
  },
});

const { actions, reducer } = businessSlice;

export const { getBusiness } = actions;
export default reducer;
