import React from "react";
import PropTypes from "prop-types";

import { Route, Redirect } from "react-router-dom";

export const PublicRoute = ({

  component: Component,

}) => {
  return (
    <Route
   
      component={(props) =>  <Component {...props} /> }
    />
  );
};

PublicRoute.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  component: PropTypes.func.isRequired,
};
