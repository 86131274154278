import {
  getPassengersTypes,
  createPassengersTypes,
  updatePassengersTypes,
  deletePassengersTypes,
  getTypePassengerFiltered,
} from ".";
import axios from "axios";
import { httpAction } from "../helpers/httpActionHelpers";

const baseUrl = process.env.REACT_APP_DEV + "/paseengers/types";
const baseUrl2 =process.env.REACT_APP_DEV + "/paseengers";


export function sendGetTypePassengerFiltered(idType) {
  return httpAction(getTypePassengerFiltered().type, async ({ getState }) => {
    const token = getState().auth.token;
    const response = await axios({
      method: "GET",
      url: baseUrl2 + "/passengersTypesById?idType=" + idType,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
    return response.data.data;
  });
}

export function sendGetPassengersTypes() {
  return httpAction(getPassengersTypes().type, async ({ getState }) => {
    const token = getState().auth.token;
    const response = await axios({
      method: "GET",
      url: baseUrl,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
    return response.data.data;
  });
}

export function sendPostPassengersTypes(data) {
  return httpAction(createPassengersTypes().type, async ({ getState }) => {
    const token = getState().auth.token;
    await axios({
      method: "POST",
      url: baseUrl,
      data: data,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
  });
}

export function sendPutPassengersTypes(data) {
  return httpAction(updatePassengersTypes().type, async ({ getState }) => {
    const token = getState().auth.token;
    await axios({
      method: "PUT",
      url: baseUrl,
      data: data,
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
  });
}

export function sendDeletePassengersTypes({ id }) {
  return httpAction(deletePassengersTypes().type, async ({ getState }) => {
    const token = getState().auth.token;
    await axios({
      method: "DELETE",
      url: baseUrl,
      data: { id },
      headers: { "SERVICIOS-URBANOS-TOKEN": token },
    });
  });
}
