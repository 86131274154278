import { Button, Card, Grid, Container, ButtonBase } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { sendGetReprocesarCheckIn } from "../../core/passenger/asyncPassenger";
import { Checkbox } from "./checkBox";



export default function AdministrarQR(id) {

    const dispatch = useDispatch();
    const { CheckIn, updatingCheckIn, getCheckIn, CheckInReprocesado } = useSelector((state) => state.passenger);
    const [seleccionado, setSeleccionado] = useState(false);
    const { filteredPassenger } = useSelector((state) => state.passenger)


    const handleCheckboxChange = (id) => {
        dispatch(sendGetReprocesarCheckIn(id + "_ola"))
    };

    if (CheckIn?.checkInErrorAlCerrar?.length == 0 && CheckIn?.checkInSinCerrar?.length == 0) {
        return (
            <Container style={{ justifyContent: "center", alignItems: "center" }}>
                {filteredPassenger?.map((p) => (
                    <h1 className="typeTitle"> {p.name} {p.lastName}</h1>))}
                <h1 className="typeSubTitle"> No se encontraron Checks sin cerrar...</h1>
            </Container>
        )
    }

    return (
        <>
            <Container style={{ justifyContent: "center", alignItems: "center" }}>
                {filteredPassenger?.map((p) => (
                    <h1 className="typeTitle"> {p.name} {p.lastName}</h1>))}
                <Card className="container-passenger" raised>
                    <Grid width={"auto"}>

                        {/*<div className="tableName1">
                            <h1 className="typeSubTitle">Errores al cerrar CheckIN: </h1>
                            <table className="table">
                                <thead key="thead">
                                    <tr>
                                        <th scope="col">Monto </th>
                                        <th scope="col">Fecha</th>
                                        <th scope="col">Hora Realizada</th>
                                        <th scope="col">Procesar Errores</th> 
                    <input type="checkbox" id="selectAll" name="selectAll"/>
                                    </tr>
                                </thead>
                                <tbody key="tbodyz">
                                    {CheckIn?.checkInErrorAlCerrar?.map((check) => (
                                        <tr>
                                            <td scope="row">{check?.amount && check?.amount ? check?.amount: 'No se encontraron Errores'}</td>
                                            <td scope="row">{check?.date?.day || 'No se encontraron Errores'}</td>
                                            <td scope="row">{check?.date?.hour || 'No se encontraron Errores'}</td>
                                           <Checkbox
                                                onChange={handleCheckboxChange}
                                                {...{ id: check?._id, setSeleccionado: () => setSeleccionado(false) }} /> 
                                        </tr>))}
                                </tbody>
                            </table>
                             <Button onClick={disparaProcesamientoCheckIn}>Procesar Errores</Button> 
                        </div>
                        <div>*/}

                        {/* segunda tabla */}
                        <h1 className="typeSubTitle"> Historial de CheckIN Sin Cerrar: </h1>
                        <table className="table">
                            <thead key="thead">
                                <tr>
                                    <th scope="col">Monto </th>
                                    <th scope="col">Fecha</th>
                                    <th scope="col">Hora Realizada</th>
                                    <th scope="col">Codigo de Transacción </th>

                                </tr>
                            </thead>
                            <tbody key="tbodyz">
                                {CheckIn?.checkInSinCerrar?.map((checkCerrado) => (
                                    <tr>
                                        <td scope="row">{checkCerrado?.amount || 'No se encontraron CheckIn sin cerrar'}</td>
                                        <td scope="row">{checkCerrado?.date?.day || 'No se encontraron CheckIn sin cerrar'}</td>
                                        <td scope="row">{checkCerrado?.date?.hour || 'No se encontraron CheckIn sin cerrar'}</td>
                                        <td scope="row">{checkCerrado?.transactionCode || 'No se encontraron CheckIn sin cerrar'}</td>
                                    </tr>))}
                            </tbody>
                        </table>

                    </Grid>
                </Card>
            </Container>
        </>
    )
}



