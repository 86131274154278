import React from "react";
import { connect } from "react-redux";
import { Row } from "reactstrap";

import "./dashboard.css";
import moment from "moment";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      day: "",
      hour: "",
      greetingTime: "",
    };
  }

  componentDidMount() {
    this.getDay();
    this.getHour();
    this.renderWelcomeMsg();
  }

  componentDidUpdate() {
    setTimeout(() => {
      this.getHour();
      this.getDay();
    }, 60000);
  }

  getDay() {
    const d = moment().format("LL");
    this.setState({ day: d });
  }

  getHour() {
    const h = moment().format("h:mm A"); // 3:51:47 pm
    this.setState({ hour: h });
  }

  renderWelcomeMsg = () => {
    const currentHour = moment().format("HH");
    const splitAfternoon = 12;
    const splitEvening = 17;

    if (currentHour >= splitAfternoon) {
      this.setState({ greetingTime: "Buenas tardes 🌱" });
      if (currentHour >= splitAfternoon && currentHour >= splitEvening) {
        this.setState({ greetingTime: "Buenas Noches 🌕" });
      }
    } else {
      this.setState({ greetingTime: "Buenos dias 👋" });
    }
  };

  render() {
    return (
      <div>
        <div className="col-md-4">
          <h3 className="mb-1 text-title-min">{this.state.greetingTime}</h3>
          <h3 className="mb-2 text-title">{this.props.name}. </h3>
          <div className="d-flex">
            <div>
              <p className="mr-5 text-subtitle"> {this.state.day}</p>
            </div>
            <div className="ml-3 text-subtitle">
              <p>{this.state.hour}</p>
            </div>
          </div>
          <p></p>
        </div>
        <Row>
          <div className="container col-12 mt-12 mb-12">
            <div className="row">
              <div className="col-md-4">
                <div className="card p-3 mb-2">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      <div className="icon">{"1"}</div>
                      <div className="ms-2 c-details">
                        <h6 className="mb-0">Beneficio Universitario</h6>{" "}
                        <span>40 Pasajes semanales</span>
                      </div>
                    </div>
                    <div className="badge">
                      {""}
                      <span>Educación</span>{" "}
                    </div>
                  </div>
                  <div className="mt-3">
                    <h3 className="heading">
                      1 Pasajero
                      <br />
                      Universitario
                    </h3>
                    <div className="mt-3">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "10%" }}
                          aria-valuenow="10"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="mt-3">
                        {" "}
                        <span className="text1">
                          2 Usuarios{" "}
                          <span className="text2">de 10 Registrados</span>
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="card p-3 mb-2">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      <div className="icon">
                        {"2"}
                        <i className="bx bxl-dribbble"></i>{" "}
                      </div>
                      <div className="ms-2 c-details">
                        <h6 className="mb-0">Beneficio Educación EGB</h6>{" "}
                        <span>20 pasajes semanales</span>
                      </div>
                    </div>
                    <div className="badge">
                      {" "}
                      <span>Educación</span>{" "}
                    </div>
                  </div>
                  <div className="mt-3">
                    <h3 className="heading">
                      200 Pasajeros
                      <br />
                      Primario-Secundario
                    </h3>
                    <div className="mt-3">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "2%" }}
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="mt-3">
                        {" "}
                        <span className="text1">
                          5 Aplicados{" "}
                          <span className="text2">de 10 Registrados</span>
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card p-3 mb-2">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                      <div className="icon">
                        {"3"}
                        <i className="bx bxl-reddit"></i>{" "}
                      </div>
                      <div className="ms-2 c-details">
                        <h6 className="mb-0">Beneficio Excombatientes</h6>{" "}
                        <span>Pasajes Ilimitados</span>
                      </div>
                    </div>
                    <div className="badge">
                      {" "}
                      <span>Mayores</span>{" "}
                    </div>
                  </div>
                  <div className="mt-3">
                    <h3 className="heading">
                      200 Pasajeros <br />
                      Excombatientes
                    </h3>
                    <div className="mt-3">
                      <div className="progress">
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{ width: "50%" }}
                          aria-valuenow="50"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div className="mt-3">
                        {" "}
                        <span className="text1">
                          52 Aplicados{" "}
                          <span className="text2">de 100 Registrados</span>
                        </span>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  name: state.auth.name,
  token: state.auth.token,
});

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps())(Dashboard);