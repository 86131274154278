import { useSelector } from "react-redux";

const baseUrl = process.env.REACT_APP_DEV;

const fetchWithoutToken = (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`;

  if (method === "GET") {
    console.log(url);
    return fetch(url);
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }
};

/* const fetchWithToken = (endpoint, data, method = "GET") => {
  const url = `${baseUrl}/${endpoint}`;
  const token = useSelector((state) => state.auth.token);
  if (method === "GET") {
    return fetch(url, {
      method,
      headers: {
        "SERVICIOS-URBANOS-TOKEN": token,
      },
    });
  } else {
    return fetch(url, {
      method,
      headers: {
        "Content-type": "application/json",
        "SERVICIOS-URBANOS-TOKEN": token,
      },
      body: JSON.stringify(data),
    });
  }
}; */

export { fetchWithoutToken };
