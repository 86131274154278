import { useEffect } from "react";
import SUMO from "../../assets/images/SUMO.png"
import SUMO1 from "../../assets/images/SUMO1.png"
import QRcode from "react-qr-code";
import "./index.css"
import { sendGetQR } from "../../core/passenger/asyncPassenger";

export function readfiles(e) {
  const files = e.currentTarget.files;
  const arrayImagen = [];
  Object.keys(files).forEach((i) => {
    const file = files[i];
    let url = URL.createObjectURL(file);
    arrayImagen.push({
      name: file.name,
      url,
      file
    });
  });
  return arrayImagen;
}


export default function DownloadDocumentHTMLM({ id, setDownloadMayores, qr, name, lastName, dni, previousImage,desde, hasta }) {


  const styles = {
    card: { left: 0, top: 0, position: "relative" },
    target: {
      width: "380px",
      height: "230px",
      position: "relative",
      zIndex: "-1",
    },
    target1: {
      width: "380px",
      height: "240px",
      position: "relative",
      zIndex: "0"
    },
    generatedQr: {
      position: "absolute",
      top: "10px",
      left: "30px",
      zIndex: 1,
    },
    title: {
      fontSize: "20px",
      fontWeight: "bold",
      fontFamily: "sans-serif",
    },
    subtitle: {
      fontSize: "20px",
      left: "190px",
      position: "absolute",
      top: "355px",
      fontFamily: "sans-serif",
      zIndex: 5,
    },
    subtitle1: {
      fontSize: "19px",
      left: "35px",
      position: "absolute",
      top: "430px",
      fontFamily: "sans-serif",
      zIndex: 5,
    },
    fotoPerfil: {
      top: "125px",
      left: "35px",
      width: "30%",
      zIndex: 8,
    },
    FechaInicio: {
      transform: "rotate(-90deg)",
      top: "100px",
      left: "160px",
      position: "absolute",
      fontSize: "14px",
      color: "#FFFFFF",
      fontFamily: "sans-serif"
    },

  };

  useEffect(() => {
    if (qr) {
      const renderDiv = document.getElementById("render");
      const html = renderDiv?.innerHTML;
      var ventana = window.open("", "PRINT"); // "height=400,width=600" 
      ventana.document.write("<html><head><title>" + document.title + "</title>");
      ventana.document.write("</head><body >");
      ventana.document.write(html);
      ventana.document.write("</body></html>");
      ventana.document.close();
      ventana.print();
      ventana.close();
      setDownloadMayores()
    }
  }, []);

  const fecha = new Date(desde);
  const dia = fecha.getDate();
  const mes = fecha.getMonth() + 1;
  const anio = fecha.getFullYear();
  const desdeFecha = `${dia}/${mes}/${anio}`;
  const fecha2 = new Date(hasta);
  const dia2 = fecha2.getDate();
  const mes2 = fecha2.getMonth() + 1;
  const anio2 = fecha2.getFullYear();
  const hastaFecha = `${dia2}/${mes2}/${anio2}`;



  return (
    <div style={{ display: "none" }}>
      <div id="render">
        <div>
          <h3 style={styles.FechaInicio}> {desdeFecha} Valido: {hastaFecha} </h3>
        </div>
        <div style={styles.card}>
          <QRcode value={qr}
            id="QRCodeScaled"
            size={200}
            bgColor = {'white'}
            style={{
              height: "auto", maxWidth: "100%", width: "100%", position: "absolute",
              top: "9px",
              left: "30px",
              zIndex: 1
            }} />
          <img src={SUMO} style={styles.target} />
          <h3 style={styles.subtitle}>DNI: {dni}</h3>
          <h3 style={styles.subtitle1}>{name + "  " + lastName}</h3>

          {previousImage.map((imagen) => (<img src={imagen.url} style={styles.fotoPerfil} />))}
        </div>
      </div>
    </div>
  );
}