import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  schedules: [],
  updatingSchedules: true,
  schedulesUpdateFailed: false,

  createSchedulesSuccess: false,
  createSchedulesFailed: false,

  updateSchedulesSuccess: false,
  updateSchedulesFailed: false,

  deleteSchedulesSuccess: false,
  deleteSchedulesFailed: false,
};

const schedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    getSchedules: (state) => state,
    getSchedulesStarted: (state) => initialState,
    getSchedulesSuccess: (state, action) => {
      state.schedules = action.payload;
      state.updatingSchedules = false;
    },
    getSchedulesFailed: (state) => {
      state.updatingSchedules = false;
      state.schedulesUpdateFailed = true;
    },
    cleanGetSchedules: (state) => {
      state.updatingSchedules = true;
      state.schedulesUpdateFailed = false;
    },

    createSchedules: (state) => state,
    createSchedulesStarted: (state) => {
      schedulesSlice.caseReducers.cleanCreateSchedules(state);
    },
    createSchedulesSuccess: (state) => {
      state.createSchedulesSuccess = true;
    },
    createSchedulesFailed: (state) => {
      state.createSchedulesFailed = true;
    },
    cleanCreateSchedules: (state) => {
      state.createSchedulesSuccess = false;
      state.createSchedulesFailed = false;
    },

    updateSchedules: (state) => state,
    updateSchedulesStarted: (state) => {
      schedulesSlice.caseReducers.cleanUpdateSchedules(state);
    },
    updateSchedulesSuccess: (state) => {
      state.updateSchedulesSuccess = true;
    },
    updateSchedulesFailed: (state) => {
      state.updateSchedulesFailed = true;
    },
    cleanUpdateSchedules: (state) => {
      state.updateSchedulesSuccess = false;
      state.updateSchedulesFailed = false;
    },

    deleteSchedules: (state) => state,
    deleteSchedulesStarted: (state) => {
      schedulesSlice.caseReducers.cleanDeleteSchedules(state);
    },
    deleteSchedulesSuccess: (state) => {
      state.deleteSchedulesSuccess = true;
    },
    deleteSchedulesFailed: (state) => {
      state.deleteSchedulesFailed = true;
    },
    cleanDeleteSchedules: (state) => {
      state.deleteSchedulesSuccess = false;
      state.updateSchedulesFailed = false;
    },
  },
});

const { actions, reducer } = schedulesSlice;

export const {
  getSchedules,
  createSchedules,
  updateSchedules,
  deleteSchedules,
  cleanCreateSchedules,
  cleanUpdateSchedules,
  cleanDeleteSchedules,
} = actions;
export default reducer;
