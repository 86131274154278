import { Button, Card, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { sendGetreactivateQrStatic } from "../../core/passenger/asyncPassenger";
import { Boton } from "./Boton"

export default function SaldoPassenger() {

  const dispatch = useDispatch();
  const { filteredBalanceAndQr } = useSelector((state) => state.passenger);
  const info = filteredBalanceAndQr?.qrStatic

//console.log(filteredBalanceAndQr);

  const handleButtonChange = (id) => {
    dispatch(sendGetreactivateQrStatic(id))
  }

  return (
    <>
      <h4 className="text-subtitle">
        Desde esta solapa podras administrar la información de los
        QR , para activar un qr apreta el boton "Activar QR" y luego , busca nuevamente al pasajero para asegurarte que este se haya activado.
      </h4>
      <h1 className="typeTitle">Saldo Disponible: ${filteredBalanceAndQr?.balance} </h1>
      <h4 className="typeSubTitle">Cantidad QR Estáticos: {filteredBalanceAndQr?.qrStatic?.length} </h4>

      <h1 className="typeSubTitle">Lista de QR Estáticos: </h1>

      {/*  {info?.map((qr) => (
        <tr><h6 className="typeSubTitle">{qr?.qr}</h6>
          <h4>ESTADO: {qr?.status}</h4>
          <Boton onChange={handleButtonChange}
            {...{ id: qr?._id, setSeleccionado: () => setSeleccionado(false) }} />
        </tr>))} */}

      <table className="table">
        <thead key="thead">
          <tr>
            <th scope="col">QR </th>
            <th scope="col">Estado</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody key="tbodyz">
          {info?.map((qr) => (
            <tr>
              <th scope="row">{qr?.qr}</th>
              <td >{qr?.status}</td>
              <td><Boton onChange={handleButtonChange}
                {...{ id: qr?._id, setSeleccionado: () => setSeleccionado(false) }} /></td>
            </tr>))}
        </tbody>
      </table>

    </>
  )
}
