import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  passengersTypes: [],
  updatingPassengersTypes: true,
  passengersTypesUpdateFailed: false,

  createPassengersTypesSuccess: false,
  createPassengersTypesFailed: false,

  updatePassengersTypesSuccess: false,
  updatePassengersTypesFailed: false,

  deletePassengersTypesSuccess: false,
  deletePassengersTypesFailed: false,

  filteredTypePassenger: [],
  getPassengerTypeFiltered: true,
  getPassengerTypeFilteredFailed: false,


};

const passengersTypesSlice = createSlice({
  name: "passengersTypes",
  initialState,
  reducers: {
    getPassengersTypes: (state) => state,
    getPassengersTypesStarted: (state) => initialState,
    getPassengersTypesSuccess: (state, action) => {
      state.passengersTypes = action.payload;
      state.updatingPassengersTypes = false;
    },
    getPassengersTypesFailed: (state) => {
      state.updatingPassengersTypes = false;
      state.passengersTypesUpdateFailed = true;
    },
    cleanGetPassengersTypes: (state) => {
      state.updatingPassengersTypes = true;
      state.passengersTypesUpdateFailed = false;
    },

    createPassengersTypes: (state) => state,
    createPassengersTypesStarted: (state) => {
      passengersTypesSlice.caseReducers.cleanCreatePassengersTypes(state);
    },
    createPassengersTypesSuccess: (state) => {
      state.createPassengersTypesSuccess = true;
    },
    createPassengersTypesFailed: (state) => {
      state.createPassengersTypesFailed = true;
    },
    cleanCreatePassengersTypes: (state) => {
      state.createPassengersTypesSuccess = false;
      state.createPassengersTypesFailed = false;
    },

    updatePassengersTypes: (state) => state,
    updatePassengersTypesStarted: (state) => {
      passengersTypesSlice.caseReducers.cleanUpdatePassengersTypes(state);
    },
    updatePassengersTypesSuccess: (state) => {
      state.updatePassengersTypesSuccess = true;
    },
    updatePassengersTypesFailed: (state) => {
      state.updatePassengersTypesFailed = true;
    },
    cleanUpdatePassengersTypes: (state) => {
      state.updatePassengersTypesSuccess = false;
      state.updatePassengersTypesFailed = false;
    },

    deletePassengersTypes: (state) => state,
    deletePassengersTypesStarted: (state) => {
      passengersTypesSlice.caseReducers.cleanDeletePassengersTypes(state);
    },
    deletePassengersTypesSuccess: (state) => {
      state.deletePassengersTypesSuccess = true;
    },
    deletePassengersTypesFailed: (state) => {
      state.deletePassengersTypesFailed = true;
    },
    cleanDeletePassengersTypes: (state) => {
      state.deletePassengersTypesSuccess = false;
      state.updatePassengersTypesFailed = false;
    },



    getTypePassengerFiltered: (state) => state,
    getTypePassengerFilteredStarted: (state) => initialState,
    getTypePassengerFilteredSuccess: (state, action) => {
      state.filteredTypePassenger = action.payload;
      state.getPassengerTypeFiltered = false
    },
    getTypePassengerFilteredFailed: (state) => {
      state.getPassengerTypeFiltered = false;
      state.getPassengerTypeFilteredFailed = true;
    },
  },
});

const { actions, reducer } = passengersTypesSlice;

export const {
  getPassengersTypes,
  createPassengersTypes,
  updatePassengersTypes,
  deletePassengersTypes,
  cleanCreatePassengersTypes,
  cleanUpdatePassengersTypes,
  cleanDeletePassengersTypes,
  getTypePassengerFiltered,
  
} = actions;
export default reducer;
