import * as React from "react";
import { Snackbar } from "@mui/material";
import { Alert } from "@mui/material";
import { hideSnackbar } from "../../core/common";

export const SnackbarType = {
  INFO: "info",
  ERROR: "error",
  WARNING: "warning",
  SUCCESS: "success",
};

const SnackBar = () => {
  const dispatch = useDispatch();
  const common = useSelector((state) => state.common);
  const { isSnackbarEnabled, snackbarMessage, snackbarType } = common;

  const handleClose = (_event, reason) => {
    dispatch(hideSnackbar());
    if (reason === "clickaway") {
      return;
    }
  };

  React.useEffect(() => {
    if (isSnackbarEnabled) {
      setTimeout(() => {
        dispatch(hideSnackbar());
      }, 3000);
    }
  }, [dispatch, isSnackbarEnabled]);

  return (
    <div>
      <Snackbar open={isSnackbarEnabled} onClose={handleClose}>
        <Alert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={snackbarType}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackBar;
