import { authLogin, authCheckingFinish, authLogout } from "../types";

const initialState = {
  checking: true,
  uid: null,
  name: null,
  idBusiness: null,
  token: "",
  role: "",
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case authLogin:
      return {
        ...state,
        ...action.payload,
        checking: false,
      };
    case authCheckingFinish:
      return {
        ...state,
        ...action.payload,
        checking: false,
      };
    case authLogout:
      return {
        checking: false,
      };

    default:
      return state;
  }
}
