import { useEffect } from "react";
import tarjetaNFC from "../../assets/images/tarjetaNFC.png";

export default function DownloadDocumentHTML({ setDownload, nfc, name, lastName }) {
  const styles = {
    card: { left: 0, top: 0, position: "relative" },
    target: {
      width: "390px",
      height: "240px",
      position: "relative",
      zIndex: "-1",
    },
    subtitle: {
      transform: "rotate(-90deg)",
      color: "black",
      fontSize: "12px",
      top: "125px",
      left: "198px",
      position: "absolute",
      fontFamily: "sans-serif",
      zIndex: 5,
    },
    subtitle1: {
      color: "black",
      fontSize: "14px",
      top: "187px",
      left: "219px",
      position: "absolute",
      fontFamily: "sans-serif",
      zIndex: 5,
      textAlign: "left",
      whiteSpace: "nowrap"
    },
  };

  const apellidoNombre = lastName + " " + name;

  useEffect(() => {
    if (nfc) {
      const renderDiv = document.getElementById("render");
      const html = renderDiv?.innerHTML;
      var ventana = window.open("", "PRINT");
      ventana.document.write("<html><head><title>" + document.title + "</title>");
      ventana.document.write("</head><body >");
      ventana.document.write(html);
      ventana.document.write("</body></html>");
      ventana.document.close();
      ventana.print();
      ventana.close();
      setDownload();
    }
  }, [nfc]);

  return (
    <div style={{ display: "none" }}>
      <div id="render">
        <div style={styles.card}>
          <img src={tarjetaNFC} style={styles.target} />
          <h3 style={styles.subtitle1}>
            {apellidoNombre.slice(0, 23)}
          </h3>
        </div>
      </div>
    </div>
  );
}
