import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  passenger: [],
  updatingPassenger: true,
  passengerUpdateFailed: false,

  createPassengerSuccess: false,
  createPassengerFailed: false,

  updatePassengerSuccess: false,
  updatePassengerFailed: false,

  deletePassengerSuccess: false,
  deletePassengerFailed: false,

  passengerType: [],
  updatingPassengerType: true,
  passengerTypeUpdateFailed: false,
  updatePassengerTypeSuccess: false,
  updatePassengerTypeFailed: false,

  qr: [],
  updatingQr: true,
  qrUpdateFailed: false,

  filteredPassenger: [],
  getPassengerFiltered: true,
  getPassengerFilteredFailed: false,

  qrInfo: [],
  updatingQrInfo: true,
  qrUpdateFailed: false,

  CheckIn: [],
  updatingCheckIn: true,
  UpdateCheckInFailed: false,

  CheckInReprocesado: [],
  updatingCheckInReprocesado: true,
  UpdateCheckInReprocesadoFailed: false,

  filteredBalanceAndQr: [],
  getQrAndBalanceFiltered: true,
  getQrAndBalanceFilteredFailed: false,

  reactivateQrStatic : [],
  getreactivateQrStatic: true,
  getreactivateQrStaticFailed: false,

  nfc: [],
  updatingNFC: true,
  nfcUpdateFailed: false,
};

const passengerSlice = createSlice({
  name: "passenger",
  initialState,
  reducers: {
    getPassenger: (state) => state,
    getPassengerStarted: (state) => initialState,
    getPassengerSuccess: (state, action) => {
      state.passenger = action.payload;
      state.updatingPassenger = false;
    },
    getPassengerFailed: (state) => {
      state.updatingPassenger = false;
      state.passengerUpdateFailed = true;
    },

    cleanGetPassenger: (state) => {
      state.updatingPassenger = true;
      state.passengerUpdateFailed = false;
    },

    createPassenger: (state) => state,
    createPassengerStarted: (state) => {
      passengerSlice.caseReducers.cleanCreatePassenger(state);
    },
    createPassengerSuccess: (state) => {
      state.createPassengerSuccess = true;
    },
    createPassengerFailed: (state) => {
      state.createPassengerFailed = true;
    },
    cleanCreatePassenger: (state) => {
      state.createPassengerSuccess = false;
      state.createPassengerFailed = false;
    },

    updatePassenger: (state) => state,
    updatePassengerStarted: (state) => {
      passengerSlice.caseReducers.cleanUpdatePassenger(state);
    },
    updatePassengerSuccess: (state) => {
      state.updatePassengerSuccess = true;
    },
    updatePassengerFailed: (state) => {
      state.updatePassengerFailed = true;
    },
    cleanUpdatePassenger: (state) => {
      state.updatePassengerSuccess = false;
      state.updatePassengerFailed = false;
    },

    deletePassenger: (state) => state,
    deletePassengerStarted: (state) => {
      passengerSlice.caseReducers.cleanDeletePassenger(state);
    },
    deletePassengerSuccess: (state) => {
      state.deletePassengerSuccess = true;
    },
    deletePassengerFailed: (state) => {
      state.deletePassengerFailed = true;
    },
    cleanDeletePassenger: (state) => {
      state.deletePassengerSuccess = false;
      state.updatePassengerFailed = false;
    },

    updatePassengerType: (state) => state,
    updatePassengerTypeStarted: (state) => {
      passengerSlice.caseReducers.cleanUpdatePassengerType(state);
    },
    updatePassengerTypeSuccess: (state) => {
      state.updatePassengerTypeSuccess = true;
    },
    updatePassengerTypeFailed: (state) => {
      state.updatePassengerTypeFailed = true;
    },
    cleanUpdatePassengerType: (state) => {
      state.updatePassengerTypeSuccess = false;
      state.updatePassengerTypeFailed = false;
    },

    getQR: (state) => state,
    getQRStarted: (state) => initialState,
    getQRSuccess: (state, action) => {
      state.qr = action.payload;
      state.updatingQr = false;
    },
    getQRFailed: (state) => {
      state.updatingQr = false;
      state.qrUpdateFailed = true;
    },

  

    getPassengerFiltered: (state) => state,
    getPassengerFilteredStarted: (state) => initialState,
    getPassengerFilteredSuccess: (state, action) => {
      state.filteredPassenger = action.payload;
      state.getPassengerFiltered = false
    },
    getPassengerFilteredFailed: (state) => {
      state.getPassengerFiltered = false;
      state.getPassengerFilteredFailed = true;
    },

    getQRInfo: (state) => state,
    getQRInfoStarted: (state) => initialState,
    getQRInfoSuccess: (state, action) => {
      state.qrInfo = action.payload;
      state.updatingQrInfo = false;
    },
    getQRInfoFailed: (state) => {
      state.updatingQrInfo = false;
      state.qrUpdateFailed = true;
    },

    //////////store del la informacion del qr

    getCheckIn: (state) => state,
    getCheckInStarted: (state) => initialState,
    getCheckInSuccess: (state, action) => {
      state.CheckIn = action.payload;
      state.updatingCheckIn = false;
    },
    getCheckInFailed: (state) => {
      state.updatingCheckIn = false;
      state.UpdateCheckInFailed = true;
    },
    /////////////
    reprocesarCheckIn: (state) => state,
    getCheckInReprocesadoStarted: (state) => initialState,
    getCheckInReprocesadoSuccess: (state, action) => {
      state.CheckInReprocesado = action.payload;
      state.updatingCheckInReprocesado = false;
    },
    getCheckInReprocesadoFailed: (state) => {
      state.updatingCheckInReprocesado = false;
      state.UpdateCheckInReprocesadoFailed = true;
    },

    ///////////apartado balanceQR////////

    getBalanceAndQrFiltered: (state) => state,
    getBalanceAndQrFilteredStarted: (state) => initialState,
    getBalanceAndQrFilteredSuccess: (state, action) => {
      state.filteredBalanceAndQr = action.payload;
      state.getQrAndBalanceFiltered = false
    },
    getBalanceAndQrFilteredFailed: (state) => {
      state.getQrAndBalanceFiltered = false;
      state.getQrAndBalanceFilteredFailed = true;
    },
/////////////apartado reactivar qr/////
getReactivateQrStatic: (state) => state,
    getReactivateQrStaticStarted: (state) => initialState,
    getReactivateQrStaticSuccess: (state, action) => {
      state.reactivateQrStatic = action.payload;
      state.getreactivateQrStatic = false
    },
    getReactivateQrStaticFailed: (state) => {
      state.getreactivateQrStatic = false;
      state.getreactivateQrStaticFailed = true;
    },

  },
});

const { actions, reducer } = passengerSlice;

export const {
  getPassenger,
  createPassenger,
  updatePassenger,
  deletePassenger,
  cleanCreatePassenger,
  cleanUpdatePassenger,
  cleanDeletePassenger,
  updatePassengerType,
  cleanUpdatePassengerType,
  getQR,
  getNFC,
  getPassengerFiltered,
  getQRInfo,
  getCheckIn,
  reprocesarCheckIn,
  getBalanceAndQrFiltered,
  getReactivateQrStatic
} = actions;
export default reducer;
