import React, { useState } from "react";
import { Card, Grid } from "@mui/material";
import { Edit, Cancel } from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Select from "../../components/select";
import "./index.scss";
import { useDispatch } from "react-redux";
import { sendPutPassengerType } from "../../core/passenger/asyncPassenger";


export default function CardPassengerType({ types, typePassengerId, days }) {
  const { id } = useParams();
  const [typePassenger, setTypePassenger] = useState(typePassengerId);
  const [isDisabled, setIsDisabled] = useState(true);
  const dispatch = useDispatch();

  const BenefitsSchema = Yup.object().shape({
    type_passenger: Yup.string().required("Seleccione un beneficio"),
  });

  const formik = useFormik({
    initialValues: {
      type_passenger: typePassengerId?._id,
    },
    validationSchema: BenefitsSchema,
    onSubmit: (initialValues, { resetForm }) => {
      setIsDisabled(true);
      dispatch(
        sendPutPassengerType({ idType: initialValues.type_passenger, id })
      );
      resetForm();
    },
  });

  const { handleSubmit, setValues, values } = formik;

  
  const location = useLocation();
  const { dataRow } = location.state;

  function handleSubmitedForm() {
      sweetAlert(
        `Cambiaste correctamente el Tipo de Pasajero`,
        `Si deseas asegurarte, busca nuevamente al pasajero por su dni`,
        "success"
     );
  };
  

  return (
   
      <>

      <div className="container-button">
        <button
          className="buttonTopRightCard"
          onClick={() => {
            setIsDisabled(!isDisabled);
          }}>
          {isDisabled && <Edit />}
          {!isDisabled && <Cancel />}
        </button>
      </div>

      {!isDisabled && (
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={3}>
              <Select
                items={types.map((e) => {
                  return {
                    title: e.name,
                    value: e._id,
                  };
                })}
                value={values}
                setValue={(e) => {
                  setValues(e);
                  setTypePassenger(
                    types.find((t) => t._id === e.type_passenger)
                  );
                }}
                name={"type_passenger"}
                title={"Tipo de pasajero"}
              />
            </Stack>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={handleSubmitedForm}
              disabled={!(formik.isValid && formik.dirty)}>
              Modificar beneficio
            </LoadingButton>
          </Form>
        </FormikProvider> )}

      <Grid>
        <Grid mb={0}>
          <Grid container m="1rem">
            <h1 className="typeTitle">Tipo de pasajero: </h1>
            <h1 className="typeSubTitle">{typePassenger?.name}</h1>
          </Grid>
        </Grid>
        <Grid mb={0}>
          <Grid container item m="1rem">
            <h6 className="typeKeyDescription">Número de usos:</h6>
            {typePassenger?.numberOfUses ? (
              <h6 className="typeValueDescription">
                {typePassenger?.numberOfUses}
              </h6>
            ) : (
              <h6 className="typeDescriptionNull">Sin limite </h6>
            )}
          </Grid>
          <Grid container item m="1rem">
            <h6 className="typeKeyDescription">Descuento: </h6>
            <h6 className="typeDiscount"> {typePassenger?.discountRate}%</h6>
          </Grid>
        </Grid>
        {typePassenger?.hourRange?.length === 0 && (
          <Grid container m="1rem" mb={1}>
            <h6 className="typeKeyDescription">Rango de horarios:</h6>

            <h6 className="typeDescriptionNull">Sin limite</h6>
          </Grid>
        )}
        
      </Grid>




      </>

  );
}