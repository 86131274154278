import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lines: [],
  updatingLines: true,
  linesUpdateFailed: false,

  createLinesSuccess: false,
  createLinesFailed: false,

  updateLinesSuccess: false,
  updateLinesFailed: false,

  deleteLinesSuccess: false,
  deleteLinesFailed: false,
};

const linesSlice = createSlice({
  name: "lines",
  initialState,
  reducers: {
    getLines: (state) => state,
    getLinesStarted: (state) => initialState,
    getLinesSuccess: (state, action) => {
      state.lines = action.payload;
      state.updatingLines = false;
    },
    getLinesFailed: (state) => {
      state.updatingLines = false;
      state.linesUpdateFailed = true;
    },
    cleanGetLines: (state) => {
      state.updatingLines = true;
      state.linesUpdateFailed = false;
    },

    createLines: (state) => state,
    createLinesStarted: (state) => {
      linesSlice.caseReducers.cleanCreateLines(state);
    },
    createLinesSuccess: (state) => {
      state.createLinesSuccess = true;
    },
    createLinesFailed: (state) => {
      state.createLinesFailed = true;
    },
    cleanCreateLines: (state) => {
      state.createLinesSuccess = false;
      state.createLinesFailed = false;
    },

    updateLines: (state) => state,
    updateLinesStarted: (state) => {
      linesSlice.caseReducers.cleanUpdateLines(state);
    },
    updateLinesSuccess: (state) => {
      state.updateLinesSuccess = true;
    },
    updateLinesFailed: (state) => {
      state.updateLinesFailed = true;
    },
    cleanUpdateLines: (state) => {
      state.updateLinesSuccess = false;
      state.updateLinesFailed = false;
    },

    deleteLines: (state) => state,
    deleteLinesStarted: (state) => {
      linesSlice.caseReducers.cleanDeleteLines(state);
    },
    deleteLinesSuccess: (state) => {
      state.deleteLinesSuccess = true;
    },
    deleteLinesFailed: (state) => {
      state.deleteLinesFailed = true;
    },
    cleanDeleteLines: (state) => {
      state.deleteLinesSuccess = false;
      state.updateLinesFailed = false;
    },
  },
});

const { actions, reducer } = linesSlice;

export const {
  getLines,
  createLines,
  updateLines,
  deleteLines,
  cleanCreateLines,
  cleanUpdateLines,
  cleanDeleteLines,
} = actions;
export default reducer;
