import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  branchs: [],
  updatingBranchs: true,
  branchsUpdateFailed: false,

  createBranchsSuccess: false,
  createBranchsFailed: false,

  updateBranchsSuccess: false,
  updateBranchsFailed: false,

  deleteBranchsSuccess: false,
  deleteBranchsFailed: false,
};

const branchsSlice = createSlice({
  name: "branchs",
  initialState,
  reducers: {
    getBranchs: (state) => state,
    getBranchsStarted: (state) => initialState,
    getBranchsSuccess: (state, action) => {
      state.branchs = action.payload;
      state.updatingBranchs = false;
    },
    getBranchsFailed: (state) => {
      state.updatingBranchs = false;
      state.branchsUpdateFailed = true;
    },
    cleanGetBranchs: (state) => {
      state.updatingBranchs = true;
      state.branchsUpdateFailed = false;
    },

    createBranchs: (state) => state,
    createBranchsStarted: (state) => {
      branchsSlice.caseReducers.cleanCreateBranchs(state);
    },
    createBranchsSuccess: (state) => {
      state.createBranchsSuccess = true;
    },
    createBranchsFailed: (state) => {
      state.createBranchsFailed = true;
    },
    cleanCreateBranchs: (state) => {
      state.createBranchsSuccess = false;
      state.createBranchsFailed = false;
    },

    updateBranchs: (state) => state,
    updateBranchsStarted: (state) => {
      branchsSlice.caseReducers.cleanUpdateBranchs(state);
    },
    updateBranchsSuccess: (state) => {
      state.updateBranchsSuccess = true;
    },
    updateBranchsFailed: (state) => {
      state.updateBranchsFailed = true;
    },
    cleanUpdateBranchs: (state) => {
      state.updateBranchsSuccess = false;
      state.updateBranchsFailed = false;
    },

    deleteBranchs: (state) => state,
    deleteBranchsStarted: (state) => {
      branchsSlice.caseReducers.cleanDeleteBranchs(state);
    },
    deleteBranchsSuccess: (state) => {
      state.deleteBranchsSuccess = true;
    },
    deleteBranchsFailed: (state) => {
      state.deleteBranchsFailed = true;
    },
    cleanDeleteBranchs: (state) => {
      state.deleteBranchsSuccess = false;
      state.updateBranchsFailed = false;
    },
  },
});

const { actions, reducer } = branchsSlice;

export const {
  getBranchs,
  createBranchs,
  updateBranchs,
  deleteBranchs,
  cleanCreateBranchs,
  cleanUpdateBranchs,
  cleanDeleteBranchs,
} = actions;
export default reducer;
