import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modalities: [],
  updatingModalities: true,
  modalitiesUpdateFailed: false,

  createModalitiesSuccess: false,
  createModalitiesFailed: false,

  updateModalitiesSuccess: false,
  updateModalitiesFailed: false,

  deleteModalitiesSuccess: false,
  deleteModalitiesFailed: false,

  modalitiesTypes: [],
  updatingModalitiesTypes: true,
  modalitiesTypesUpdateFailed: false,
};

const modalitiesSlice = createSlice({
  name: "modalities",
  initialState,
  reducers: {
    getModalities: (state) => state,
    getModalitiesStarted: (state) => initialState,
    getModalitiesSuccess: (state, action) => {
      state.modalities = action.payload;
      state.updatingModalities = false;
    },
    getModalitiesFailed: (state) => {
      state.updatingModalities = false;
      state.modalitiesUpdateFailed = true;
    },
    cleanGetModalities: (state) => {
      state.updatingModalities = true;
      state.modalitiesUpdateFailed = false;
    },

    createModalities: (state) => state,
    createModalitiesStarted: (state) => {
      modalitiesSlice.caseReducers.cleanCreateModalities(state);
    },
    createModalitiesSuccess: (state) => {
      state.createModalitiesSuccess = true;
    },
    createModalitiesFailed: (state) => {
      state.createModalitiesFailed = true;
    },
    cleanCreateModalities: (state) => {
      state.createModalitiesSuccess = false;
      state.createModalitiesFailed = false;
    },

    updateModalities: (state) => state,
    updateModalitiesStarted: (state) => {
      modalitiesSlice.caseReducers.cleanUpdateModalities(state);
    },
    updateModalitiesSuccess: (state) => {
      state.updateModalitiesSuccess = true;
    },
    updateModalitiesFailed: (state) => {
      state.updateModalitiesFailed = true;
    },
    cleanUpdateModalities: (state) => {
      state.updateModalitiesSuccess = false;
      state.updateModalitiesFailed = false;
    },

    deleteModalities: (state) => state,
    deleteModalitiesStarted: (state) => {
      modalitiesSlice.caseReducers.cleanDeleteModalities(state);
    },
    deleteModalitiesSuccess: (state) => {
      state.deleteModalitiesSuccess = true;
    },
    deleteModalitiesFailed: (state) => {
      state.deleteModalitiesFailed = true;
    },
    cleanDeleteModalities: (state) => {
      state.deleteModalitiesSuccess = false;
      state.updateModalitiesFailed = false;
    },
    getModalitiesTypes: (state) => state,
    getModalitiesTypesStarted: (state) => {
      modalitiesSlice.caseReducers.cleanGetModalitiesTypes(state);
    },
    getModalitiesTypesSuccess: (state, action) => {
      state.modalitiesTypes = action.payload;
      state.updatingModalitiesTypes = false;
    },
    getModalitiesTypesFailed: (state) => {
      state.updatingModalitiesTypes = false;
      state.modalitiesTypesUpdateFailed = true;
    },
    cleanGetModalitiesTypes: (state) => {
      state.updatingModalitiesTypes = true;
      state.modalitiesTypesUpdateFailed = false;
    },
  },
});

const { actions, reducer } = modalitiesSlice;

export const {
  getModalities,
  createModalities,
  updateModalities,
  deleteModalities,
  cleanCreateModalities,
  cleanUpdateModalities,
  cleanDeleteModalities,
  getModalitiesTypes,
} = actions;
export default reducer;
