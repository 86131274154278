import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  services: [],
  updatingServices: true,
  servicesUpdateFailed: false,

  createServicesSuccess: false,
  createServicesFailed: false,

  updateServicesSuccess: false,
  updateServicesFailed: false,

  deleteServicesSuccess: false,
  deleteServicesFailed: false,
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    getServices: (state) => state,
    getServicesStarted: (state) => initialState,
    getServicesSuccess: (state, action) => {
      state.services = action.payload;
      state.updatingServices = false;
    },
    getServicesFailed: (state) => {
      state.updatingServices = false;
      state.servicesUpdateFailed = true;
    },
    cleanGetServices: (state) => {
      state.updatingServices = true;
      state.servicesUpdateFailed = false;
    },

    createServices: (state) => state,
    createServicesStarted: (state) => {
      servicesSlice.caseReducers.cleanCreateServices(state);
    },
    createServicesSuccess: (state) => {
      state.createServicesSuccess = true;
    },
    createServicesFailed: (state) => {
      state.createServicesFailed = true;
    },
    cleanCreateServices: (state) => {
      state.createServicesSuccess = false;
      state.createServicesFailed = false;
    },

    updateServices: (state) => state,
    updateServicesStarted: (state) => {
      servicesSlice.caseReducers.cleanUpdateServices(state);
    },
    updateServicesSuccess: (state) => {
      state.updateServicesSuccess = true;
    },
    updateServicesFailed: (state) => {
      state.updateServicesFailed = true;
    },
    cleanUpdateServices: (state) => {
      state.updateServicesSuccess = false;
      state.updateServicesFailed = false;
    },

    deleteServices: (state) => state,
    deleteServicesStarted: (state) => {
      servicesSlice.caseReducers.cleanDeleteServices(state);
    },
    deleteServicesSuccess: (state) => {
      state.deleteServicesSuccess = true;
    },
    deleteServicesFailed: (state) => {
      state.deleteServicesFailed = true;
    },
    cleanDeleteServices: (state) => {
      state.deleteServicesSuccess = false;
      state.updateServicesFailed = false;
    },
  },
});

const { actions, reducer } = servicesSlice;

export const {
  getServices,
  createServices,
  updateServices,
  deleteServices,
  cleanCreateServices,
  cleanUpdateServices,
  cleanDeleteServices,
} = actions;
export default reducer;
