import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { sendGetBalanceAndQRFiltered, sendGetPassengerFiltered } from "../../core/passenger/asyncPassenger";
import Loading from "../../components/loading";
import EditableTable from "../../components/editableTable";
import Page from "../../components/page";
import { Box, Container, Grid } from "@mui/material";
import Images from "../../assets/images/buses.svg";
import "./indexDNI.scss";
import sweetAlert from "sweetalert";
import { sendGetTypePassengerFiltered } from "../../core/passengersTypes/asyncPassengersTypes";

export default function PassengerxDni() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [dataTable, setDataTable] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isFailed, setIsFailed] = useState(false);
    const [BusquedaDni, setBusquedaDni] = useState("");
    const { filteredPassenger, getPassengerFiltered, getPassengerFilteredFailed } = useSelector((state) => state.passenger);

    const columns = [
        {
            title: "Nombre",
            field: "name",
        },
        {
            title: "Apellido",
            field: "lastName",
        },
        {
            title: "CUIT",
            field: "cuit",
        },
        {
            title: "DNI",
            field: "dni",
        },
        {
            title: "Telefono",
            field: "phoneNumber",
        },
        {
            title: "Email",
            field: "email",
        },
        {
            title: "Apertura de Cuenta",
            field: "timestampRegister"
        }
    ];


    function handleSearchPassengerId(_event, dataRow) {
        dispatch(sendGetPassengerFiltered(dataRow.dni));
        dispatch(sendGetTypePassengerFiltered(dataRow.idType));
        dispatch(sendGetBalanceAndQRFiltered(dataRow.idPassenger));
        history.push({ pathname: `/pasajero/${dataRow.id}`, state: { dataRow } });
    }


    useEffect(() => {
        setIsLoading(false);
        const prxsData = [];
        if (!getPassengerFiltered && !getPassengerFilteredFailed) {
            if (filteredPassenger) {
                filteredPassenger.forEach((p) => {
                    prxsData.push({
                        idPassenger: p.idPassenger || "-",
                        id: p._id,
                        name: p.name || "-",
                        lastName: p.lastName || "-",
                        cuit: p.cuit || "-",
                        dni: p.dni || "-",
                        phoneNumber: p.phoneNumber || "-",
                        email: p.email || "-",
                        idType: p.idType || "-",
                        timestampRegister: p.timestampRegister || "-",
                        address: p.address || "-",
                        birthDate: p.birthDate || "-",
                        city: p.city || "-",
                        department: p.department || "-",
                        postalCode: p.postalCode || "-",
                        province: p.province || "-",

                    });
                });
            }
        }
        setDataTable(prxsData);
    }, [filteredPassenger, getPassengerFiltered, getPassengerFilteredFailed]);


    useEffect(() => {
        setIsLoading(false)
        setIsFailed(getPassengerFilteredFailed);
        if (getPassengerFilteredFailed) {
            sweetAlert(
                "Error",
                "No se pudo realizar la acción, intente de nuevo",
                "error"
            )
        }
    }, [getPassengerFilteredFailed]);

    useEffect(() => {
        if (BusquedaDni.length >= 7)
            return dispatch(sendGetPassengerFiltered(BusquedaDni))
    }, [BusquedaDni]);

    function cambiar(e) { setBusquedaDni(e.target.value) }

    return (
        <Page title="Pasajeros">
            <Container>
                <Grid container spacing={4} wrap="nowrap">
                    <Grid item xl={9} sm={8}>
                        <Box>
                            <h1 className="text-title">Administrar pasajeros</h1>
                            <h4 className="text-subtitle">
                                Desde esta solapa podras administrar la información de los
                                pasajeros y sus beneficios con una búsqueda por dni.
                            </h4>
                            <img src={Images} className="image" alt="image" />
                        </Box>
                    </Grid>
                    <Grid item xs={9} sm={"auto"}>
                        <Box>
                            {isLoading && <Loading />}
                            <>
                                <h3 className="text-title">Buscar : </h3>
                                <div className="input-group">
                                    <div className="md-form active-cyan-2 mb-3">
                                        <input className="form-control"
                                            type="text"
                                            placeholder="Buscar Pasajero por DNI"
                                            aria-label="Search" onChange={cambiar} />
                                    </div>
                                </div>
                                <EditableTable title="Pasajeros"
                                    columns={columns}
                                    data={dataTable}
                                    onRowClick={handleSearchPassengerId} />
                            </>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
