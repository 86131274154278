import Dashboard from "../views/dashboard/dashboard.jsx";
import AdminPassengers from "../views/adminPassenger";
import PassengerxDni from "../views/PasengerxDni/passengerxDni.jsx";
import QRInformacion from "../views/QRinfo/QrInfo.jsx";
import AdministrarQR from "../views/QRinfo/AdministrarQR.jsx";

export const ThemeRoutesSuperAdmin = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ti-loop",
    component: Dashboard,
  },
/*   {
    path: "/QRInformacion",
    name: "QRInformacion",
    icon: "ti-info-alt",
    component: QRInformacion,
  },  */
  {
    path: "/QR/:id",
    name: "AdministrarQR",
    icon: "ti-user",
    component: AdministrarQR,
  },
  {
    path: "/pasajero/:id",
    name: "Administrar pasajero",
    icon: "ti-user",
    component: AdminPassengers,
  },
  {
    path: "/pasajerosxDni",
    name: "Pasajeros por DNI",
    icon: "ti-user",
    component: PassengerxDni,
  },

  { path: "/", pathTo: "/dashboard", name: "Dashboard", redirect: true },
];

export const ThemeRoutesATCAdmin = [

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ti-loop",
    component: Dashboard,
  },
  {
    path: "/pasajerosxDni",
    name: "Pasajeros por DNI",
    icon: "ti-user",
    component: PassengerxDni,
  },
/*   {
    path: "/QRInformacion",
    name: "QRInformacion",
    icon: "ti-info-alt",
    component: QRInformacion,
  },  */
  {
    path: "/pasajero/:id",
    name: "Administrar pasajero",
    icon: "ti-user",
    component: AdminPassengers,
  },
  {
    path: "/QR/:id",
    name: "AdministrarQR",
    icon: "ti-user",
    component: AdministrarQR,
  },
 
  { path: "*", pathTo: "/dashboard", name: "Dashboard", redirect: true },
];
export const ThemeRoutesBusinessAdmin = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ti-loop",
    component: Dashboard,
  },

  { path: "*", pathTo: "/dashboard", name: "Dashboard", redirect: true },
];
