import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendGetReprocesarCheckIn } from "../../core/passenger/asyncPassenger";
import DoneAllIcon from '@mui/icons-material/DoneAll';



export const Checkbox = ({ id, onChange = () => { } }) => {
    const { CheckIn } = useSelector((state) => state.passenger);
    const [_id, set_id] = useState("");
    const [actualState, changeCheckState] = useState(false)

    const handleCheckbox = e => {
        set_id(e.target.checked);
        if (onChange) { onChange(id, e.target.checked) }
    }

    return (
        <>
            {/*<input
                checked={_id}
                id={id}
                onChange={handleCheckbox}
                type="checkbox" />*/}
            <Button checked={_id} id={id} onClick={handleCheckbox}>
                <DoneAllIcon />
            </Button>
        </>

    );
};
//<Button onClick={_id} id={id}onChange={handleCheckbox}>JBDDSJBFJBFS</Button>