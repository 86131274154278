import { Button } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendGetReprocesarCheckIn } from "../../core/passenger/asyncPassenger";
import QrCodeIcon from '@mui/icons-material/QrCode';


export const Boton = ({ id, onChange = () => { } }) => {
    const { filteredBalanceAndQr } = useSelector((state) => state.passenger);
    const [_id, set_id] = useState("");
    const [actualState, changeCheckState] = useState(false)

    const handleCheckbox = e => {
        set_id(e.target.checked);
        if (onChange) { onChange(id, e.target.checked) }
    }

    return (
        <>
            <Button checked={_id} id={id} onClick={handleCheckbox}>
                Activar QR
                <QrCodeIcon />
            </Button>
        </>

    );
};
