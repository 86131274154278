import React from "react";
import { useDispatch } from "react-redux";
import { useForm } from "../../core/hooks/useForm";
import { startLogin } from "../../core/auth/authAction";
import JSON from "../../../package.json";
import "./login.scss";
import logo from "../../assets/images/logosusa.png";

export const Login = () => {
  const dispatch = useDispatch();

  const [formLoginValues, handleLoginInputChange] = useForm({
    docket: "",
    pin: "",
    idBusiness: "",
  });

  const { docket, pin, idBusiness } = formLoginValues;

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(startLogin(docket, pin, idBusiness));
  };

  return (
    <div>
      <div className="container-login">
        <div className="left">
          <img src={logo} className="logo" alt="logo" />
          <div className="header">
            <h2 className="animation a1">Bienvenido de nuevo</h2>
            <h4 className="animation a2">
              Inicia sesión con tu legajo y contraseña
            </h4>
          </div>
          <div className="form" onSubmit={handleSubmit}>
            <input
              type="text"
              className="form-field animation a3 select"
              placeholder="Legajo"
              name="docket"
              value={docket}
              onChange={handleLoginInputChange}
            />
            <input
              type="password"
              className="form-field animation a4 select"
              placeholder="Pin"
              name="pin"
              value={pin}
              onChange={handleLoginInputChange}
            />
            <select
              name="idBusiness"
              type="text"
              onChange={handleLoginInputChange}
              className=" form-field form-select"
              aria-label="Default select example"
            >
              <option value="none">Seleccione una Empresa</option>
              <option value="1">Don Casimiro</option>
              <option value="2">Ntra.Sra del Rosario</option>
              <option value="3">Bencivenga</option>
              <option value="4">Tipoka</option>
              <option value="5">Servicios Urbanos</option>
              <option value="6">Next</option>
              <option value="7">Guacurari</option>

            </select>
            <div className="business-container animation a4"></div>
            <button className="animation a6" onClick={handleSubmit}>
              Iniciar Sesión
            </button>
            <p className="mt-1">ATC dev: {JSON.version}</p>
          </div>
        </div>
        <div className="right"></div>
      </div>
    </div>
  );
};

export default Login;
