import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import Login from "../views/login/login";
import Fulllayout from "../layouts/fulllayout";
import { useSelector } from "react-redux";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";

export const AppRouter = () => {
  const { token } = useSelector((state) => state.auth);

  return (
    <Router>
      <div>
        <Switch>
          <PublicRoute
            exact
            path="/"
            component={Login}
            isAuthenticated={!!token}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            component={Fulllayout}
            isAuthenticated={!!token}
          />
           <PrivateRoute
            exact
            path="/pasajerosxDni"
            component={Fulllayout}
            isAuthenticated={!!token}
          />
           <PrivateRoute
            exact
            path="/QRInformacion"
            component={Fulllayout}
            isAuthenticated={!!token}
          /> 
           <PrivateRoute
            exact
            path="/QR/:id"
            component={Fulllayout}
            isAuthenticated={!!token}
          />
          <PrivateRoute
            exact
            path="/pasajero/:id"
            component={Fulllayout}
            isAuthenticated={!!token}
          />
          <PrivateRoute
            exact
            path="/servicio"
            component={Fulllayout}
            isAuthenticated={!!token}
          />
          <PrivateRoute
            exact
            path="/modalidades"
            component={Fulllayout}
            isAuthenticated={!!token}
          />
          <PrivateRoute
            exact
            path="/beneficios"
            component={Fulllayout}
            isAuthenticated={!!token}
          />
          
        </Switch>
      </div>
    </Router>
  );
};
