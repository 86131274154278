import React from "react";
import { store } from "./core/store";
import { Provider } from "react-redux";
import { AppRouter } from "./routes/appRouter";
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from "@mui/material/styles";

export const App = () => {
  const theme = createTheme();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <AppRouter />
        </Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
