import { Helmet } from "react-helmet";
import React, { forwardRef } from "react";

const Page = forwardRef((props, ref) => {
  const { children, title, ...other } = props;

  return (
    <div ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </div>
  );
});

export default Page;
