export const startedAction = (type) => `${type}Started`;
export const successAction = (type) => `${type}Success`;
export const failedAction = (type) => `${type}Failed`;

export const httpAction = (type, asyncFunction) => {
  return {
    isHttpAction: true,
    type,
    asyncFunction,
  };
};
