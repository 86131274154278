import { fetchWithoutToken, fetchWithToken } from "../helpers/fetch";
import { authLogin, authLogout, typeRole } from "../types";
import swal from "sweetalert";
import jwt from 'jwt-decode'


export const startLogin = (docket, pin, idBusiness) => {
  return async (dispatch) => {
    const resp = await fetchWithoutToken(
      "auth",
      {
        docket: Number(docket),
        pin: pin,
        idBusiness: Number(idBusiness)
      }, "POST");
    const body = await resp.json();
    if (body.ok) {
      let tk = body.token
      const user = jwt(tk)
      const role = user.role
      dispatch(login({
        uid: body.user.id,
        name: body.user.name,
        idBusiness: body.user.idBusiness.idBusiness,
        nameBusiness: body.user.idBusiness?.name,
        role: typeRole.atc,
        token: body.token,
        tokenInitDate: new Date().getTime(),
      })
      );
     window.location="/dashboard"
    } else { swal("Error", "Parece que las credenciales no son validas", "error") }
  };
};


const login = (user) => ({
  type: authLogin,
  payload: user,
});

export const startLogout = () => {
  return (dispatch) => {
    localStorage.removeItem("SERVICIOS-URBANOS-TOKEN");
    localStorage.clear();
    dispatch(logout());
    window.location = "/";
  };
};

const logout = () => ({ type: authLogout });
