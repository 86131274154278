import {
  failedAction,
  startedAction,
  successAction,
} from "./helpers/httpActionHelpers";
import { SnackbarType } from "../components/snackbar";
import { startLogout } from "./auth/authAction";
import { forceRedirect, showSnackbar } from "./common";
import { StatusCodes } from "http-status-codes";

async function handleRequest(store, next, action) {
  try {
    next({
      type: startedAction(action.type),
    });
    const result = await action.asyncFunction(store);
    next({
      type: successAction(action.type),
      payload: result,
    });
  } catch (err) {
    next({
      type: failedAction(action.type),
      payload: err.message,
    });
    if (err.response?.status === StatusCodes.FORBIDDEN) {
      next(forceRedirect({ uri: "/" }));
      next(startLogout());
      next(
        showSnackbar({
          message: "Sesión expirada",
          type: SnackbarType.ERROR,
        })
      );
    }
  }
}

const httpMiddleware = (store) => (next) => async (action) => {
  if (action.isHttpAction) {
    await handleRequest(store, next, action);
  } else {
    return next(action);
  }
};

export default httpMiddleware;
